<template>
  <base-view
      @click:add="criar"
      @update:search-values="updateSearch"
      :search-columns="searchColumns">

    <CreateEditOrgaoDialog :item="item" v-model="editDialogOpened"
                                  @onSave="() => $refs.dataTable.refresh()"/>

    <div>
      <OrgaoDataTable ref="dataTable"
                             @click:edit="editar"
                             @click:remove="remover"
                         :search-values="searchValues"/>
    </div>
  </base-view>

</template>

<script>
import OrgaoDataTable from "../../components/shared/datatables/OrgaoDataTable";
import BaseView from "../BaseView";
import {remove} from "../../api/orgao";
import CreateEditOrgaoDialog from "../../components/shared/dialogs/CreateEditOrgaoDialog";

export default {
  name: "index",
  components: {CreateEditOrgaoDialog, BaseView, OrgaoDataTable},
  data() {
    return {
      item: null,
      editDialogOpened: false,
      selectedItems: [],
      searchValues: {},
      searchColumns: [
        {
          name: 'ID',
          column: 'id',
          type: 'number',
          prefix: '#'
        },
        {
          name: 'Código',
          column: 'cdOrgao',
          type: 'number'
        },
        {
          name: 'Descricao',
          column: 'descricao',
          type: 'text'
        },
        {
          name: 'CNPJ',
          column: 'cnpj',
          type: 'text'
        }
      ]
    }
  },
  methods: {
    updateSearch(search) {
      this.searchValues = search;
    },
    criar() {
      this.item = {};
      this.editDialogOpened = true;
    },
    editar(objeto) {
      this.item = JSON.parse(JSON.stringify(objeto));
      this.editDialogOpened = true;
    },
    async remover({id}) {
      try {
        await remove(id);
        this.$toast.success(`Órgão removido com sucesso.`, {
          timeout: 3000
        });
        this.$refs.dataTable.refresh();
      } catch (err) {
        this.$toast.error(`Não foi possível remover o Órgão.`, {
          timeout: 6000
        })
      }
    }
  },
  mounted() {
  }
}
</script>

<style scoped lang="scss">

::v-deep {
  .v-data-table__wrapper {
    height: calc(100vh - 157px);
  }
}

</style>