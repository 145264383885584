<template>
  <div>

    <responsive-menu ref="menu"
                     :title="selectedItemName"
                     :opcoes="menuOptions"
                     @click:item="action => $emit(`click:${action}`, selectedItem)"/>
    <v-data-table :headers="headers"
                  dense
                  fixed-header
                  :options.sync="pageOptions"
                  :server-items-length="totalElements"
                  :disable-sort="true"
                  :hide-default-header="$vuetify.breakpoint.mobile"
                  @click:row="onClickRow"
                  @contextmenu:row.prevent="showMenu"
                  :footer-props="{
      disableItemsPerPage: true,
      itemsPerPageOptions: [itemsPerPage],
    }"
                  :items="elements" :loading="loading">
      <template v-slot:item.id="{item}">
        <strong>#{{ item.id }}</strong>
      </template>
      <template v-slot:item.edit="{item}">
        <v-btn @click.prevent="showMenu($event, item)" small icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>


      <template v-slot:body="{items, isSelected, select}" v-if="$vuetify.breakpoint.mobile">
        <v-card v-if="items.length === 0" class="elevation-0">
          <v-card-text class="text-center">
            <p>Não há dados disponíveis. Utilize o filtro para refazer a pesquisa.</p>
          </v-card-text>
        </v-card>
        <v-list dense class="pa-0">
          <v-list-item @click="showMenu($event, item)" v-for="item of items" :key="item.id"
                       class="list-item">
            <v-list-item-avatar>
              #{{ item.id }}
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-text="item.cdOrgao"/>
              <v-list-item-title v-text="item.descricao"/>
              <v-list-item-subtitle v-text="item.cnpj"/>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>mdi-dots-vertical</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </template>

    </v-data-table>
  </div>
</template>

<script>

import {findAll} from '../../../api/orgao';
import ResponsiveMenu from "../../app/ResponsiveMenu";

/**
 * @emits click:edit
 * @emit click:remove
 */
export default {
  name: "OrgaoDataTable",
  components: {ResponsiveMenu},
  props: {
    searchValues: {
      type: Object,
    },
  },
  data() {
    return {
      selectedItem: null,
      menuOptions: [
        {
          action: 'edit',
          text: 'Editar',
          icon: 'mdi-pencil'
        },
        {
          action: 'remove',
          text: 'Remover',
          icon: 'mdi-delete',
          color: 'error'
        }
      ],
      menu: {
        positionX: 0,
        positionY: 0,
        show: false
      },
      loading: false,
      error: null,
      pageOptions: {
        page: 1
      },
      itemsPerPage: 0,
      totalElements: 0,
      elements: [],
      headers: [
        {
          value: 'id',
          text: 'ID',
          align: 'start',
          width: 100
        },
        {
          text: 'Cod. Órgão',
          value: 'cdOrgao',
          align: 'start',
          width: 100
        },
        {
          text: 'Descrição',
          value: 'descricao',
          align: 'start'
        },
        {
          text: 'CNPJ',
          value: 'cnpj',
          align: 'start'
        },
        {
          text: 'E-mail',
          value: 'email',
          align: 'start'
        },
        {
          text: 'Menu',
          width: 30,
          value: 'edit',
          align: 'end'
        }
      ],
    }
  },
  computed: {
    selectedItemName() {
      return this.selectedItem?.descricao
    },

  },
  watch: {
    searchValues: {
      deep: true,
      handler() {
        this.refresh();
      }
    },
    'pageOptions.page': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) this.refresh(newValue);
      }
    }
  },
  methods: {
    showMenu(event, item) {
      this.selectedItem = !item.item ? item : item.item;
      this.$refs.menu.open(event);
    },
    onClickRow(row) {
      this.$emit('click:row', row);
    },
    async refresh() {
      try {
        this.error = null;
        this.loading = true;
        const page = await findAll(this.pageOptions.page, this.searchValues);

        this.itemsPerPage = page.elementsPerPage;
        this.totalElements = page.totalElements;
        this.elements = page.elements;
        this.pageOptions.page = page.currentPage;

      } catch (err) {
        this.error = err;
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.pageOptions.page = 1;
    this.refresh();
  }
}
</script>

<style scoped lang="scss">
.list-item {
  &::v-deep {
    .v-list-item__content {
      max-width: calc(100vw - 128px);
    }
  }
}
.list-item:not(:last-child) {
  border-bottom: 1px solid #dedede
}

</style>